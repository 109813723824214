<template>
	<div class="single-news">
		<Header />
		<article class="content">
			<MainColumn>
				<p class="top-title color-aili-white font-size-50">
					Articoli
				</p>

				<CategoryHeaderArticles v-if="articleCategories.length > 0" />
				<div class="content-shadow">
					<div class="blocks" v-if="post">
						<SocialIcons :color="'#7209b7'" :title="post.title.rendered" />
						<article>
							<div class="lazyblock-article">
								<div class="categories color-aili-black tiny">
									<div class="post-type">Articolo</div>
									<div class="separator"></div>
									<div class="category" v-for="(category, j) in post.category_data" :key="j">
										<span class="category-name">{{ category.name }}</span>
										<span class="category-comma" v-if="j < post.category_data.length - 1">,</span>
									</div>
								</div>
								<h1 class="font-size-50 color-aili-purple" v-html="post.title.rendered" />
								<h2
									class="font-size-35 subtitle"
									v-if="post.blocks[0].attrs.subtitle"
									v-html="post.blocks[0].attrs.subtitle"
								></h2>

								<div
									class="author"
									@click="
										goTo('Expert', {
											slug: post.author_data.slug,
										})
									"
								>
									<div
										class="author-image-single"
										:style="'background-image: url(' + post.author_data.images.thumbnail + ')'"
									></div>

									<h5 class="author-name color-aili-black font-size-15">
										{{ post.author_data.first_name[0] + ' ' + post.author_data.last_name[0] }}
									</h5>
								</div>
							</div>
							<v-row no-gutters style="width: 100%">
								<v-col cols="12">
									<div
										class="image"
										v-if="post.featured_media_data"
										:style="'background-image: url(' + post.featured_media_data.large + ')'"
									>
										<Wave class="wave" color="#fff" position="top" /></div
								></v-col>
								<v-col cols="10" offset="1">
									<RenderBlocks v-if="blocks" :parentBlock="post" :blocks="blocks" />
								</v-col>
							</v-row>
						</article>
					</div>
				</div>
				<div v-if="post" class="content-shadow the-author background-aili-purple-light">
					<div class="content-shadow">
						<h2 class="last-recipes-title" :class="'color-' + pageColor">
							Selezionati per te
						</h2>
						<LazyblockLastPostByCategory color="aili-purple" :category="post.category_data[0].slug" />
						<!-- <ExpertResult v-if="author" :author="author" color="aili-purple" /> -->
					</div>
				</div>
			</MainColumn>
		</article>
	</div>
</template>
<script>
// import ExpertResult from '@/components/ui/ExpertResult.vue';
import CategoryHeaderArticles from '@/components/ui/CategoryHeaderArticles.vue';
import Header from '@/components/ui/Header.vue';
import LazyblockLastPostByCategory from '@/components/blocks/lazyblocks/lazyblock_last_posts_by_category.vue';
import SocialIcons from '@/components/ui/SocialIcons.vue';
import Wave from '@/components/ui/Wave.vue';

export default {
	name: 'Article',
	components: {
		CategoryHeaderArticles,
		Header,
		LazyblockLastPostByCategory,
		SocialIcons,
		Wave,
		// ExpertResult,
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue'),
	},
	metaInfo() {
		if (this.seo) {
			return this.formatYoastMeta(this.seo);
		}
	},
	data() {
		return {
			post: null,
			data: null,
			author: false,
			date: null,
		};
	},

	computed: {
		blocks: {
			get() {
				if (this.post) {
					return this.post.blocks;
				} else {
					return [];
				}
			},
			set() {
				if (this.post) {
					return this.post.blocks;
				} else {
					return [];
				}
			},
		},
	},

	methods: {
		async getContent() {
			this.updateProperty({
				property: 'pageColor',
				value: 'aili-purple',
			});

			let url = '/wp/v2/posts';
			let response;
			if (this.$route.query && this.$route.query.preview) {
				response = await this.getPreview(url);
			} else {
				response = await this.$api.get(url, {
					params: {
						slug: this.$route.params.slug,
						timestamp: Date.now(),
					},
				});
			}
			this.post = response.data[0];
			this.updateProperty({
				property: 'seo',
				value: response.data[0],
			});
			this.blocks = response.data[0].blocks;
			this.date = new Date(this.post.date).toLocaleDateString();

			this.setSchemaJson(this.post.qzr_schema);

			let response_author = await this.$api.get('/qzr-data/authors/experts/' + this.post.author_data.slug);
			let author = response_author.data;
			let response_last_posts = await this.$api.get('/wp/v2/posts', {
				params: {
					per_page: 3,
					'author[]': author.id,
				},
			});

			author['last_posts'] = response_last_posts.data;
			this.author = author;
		},
	},
	watch: {
		async '$route.fullPath'() {
			this.getContent();
		},
		loginData: {
			deep: true,
			handler() {
				if (this.loginData.user) {
					this.updateProperty({
						property: 'loadingData',
						value: true,
					});
					this.getContent();
				}
			},
		},
	},

	async mounted() {
		await this.getArticleCategories();
		this.getContent();
	},
};
</script>

<style lang="scss" scoped>
#app {
  .last-recipes-title {
    padding: $spacing-0 $spacing-0;
    font-size: $font-size-35;
  }
  .categories {
    margin-bottom: 10px;
  }
  .lazyblock-article {
    padding: $spacing-2;
    background: $aili-white;
    @media (max-width: $tablet-m) {
      padding: $spacing-0;
    }
  }
  .the-author {
    margin-top: $spacing-1;
  }
  .blocks {
    padding-bottom: $spacing-1;
    background: #fff;
  }
  .author {
    display: inline-block;
    width: 100%;
    margin: 0;
    margin-top: 10px;
    text-decoration: none;
    cursor: pointer;

    .author-image-single {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 14px;
      background-size: contain;
      border-radius: 100%;
    }
    .author-name {
      height: 40px;
      line-height: 40px !important;
    }
  }

  .image {
    position: relative;
    width: 100%;
    padding-top: 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .single-news {
    min-height: 100vh;
    padding-bottom: $footer-distance;
    background: $aili-purple;
  }
  .wave {
    top: -8px !important;
  }

  /* h1 {
	margin-bottom: 50px;
	padding-bottom: 0;
} */

  h2 {
    @media (max-width: $tablet-m) {
      line-height: 1.2;
    }
  }

  .subtitle {
    @media (max-width: $tablet-m) {
      margin: 5px 0;
      line-height: 1.3;
    }
  }
}
</style>
